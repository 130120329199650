export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/opd-billing/:pageId',
        component: () => import('@/views/page/company/hospital/opd/Create.vue'),
        name: 'opd-billing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/opd-billing-list/:pageId',
        component: () => import('@/views/page/company/hospital/opd/OpdBillingList.vue'),
        name: 'opd-billing-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/prescription-psc/:pageId',
        component: () => import('@/views/page/company/hospital/OpdPrescriptionPhc.vue'),
        name: 'prescription-psc',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/prescription-doctor/:pageId',
        component: () => import('@/views/page/company/hospital/OpdPrescriptionDoctor.vue'),
        name: 'prescription-doctor',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/opd-appointment-list/:pageId',
        component: () => import('@/views/page/company/hospital/OpdPrescriptionDoctor.vue'),
        name: 'opd-appointment-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-phc/:pageId',
        component: () => import('@/views/page/company/hospital/UpdatePhc.vue'),
        name: 'phc-update',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-register/:pageId',
        component: () => import('@/views/page/company/hospital/patient/register/Index.vue'),
        name: 'patient-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sticker-printer/:pageId/patient/:patientId',
        component: () => import('@/views/page/company/hospital/patient/StickerPrinter.vue'),
        name: 'sticker-printer',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-card-print/:pageId/patient/:patientId',
        component: () => import('@/views/page/company/hospital/patient/PatientCard.vue'),
        name: 'patient-card-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-opd/:pageId',
        component: () => import('@/views/page/company/hospital/PatientRegisterOPDList.vue'),
        name: 'patient-opd',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-ipd/:pageId',
        component: () => import('@/views/page/company/hospital/PatientRegisterIPDList.vue'),
        name: 'patient-ipd',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-investigation/:pageId',
        component: () => import('@/views/page/company/hospital/InvestigationList.vue'),
        name: 'patient-investigation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/patient-medicine/:pageId',
        component: () => import('@/views/page/company/hospital/MedicineList.vue'),
        name: 'patient-medicine',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-update-patient/:pageId',
        component: () => import('@/views/page/company/hospital/patient/create-update/Index.vue'),
        name: 'add-update-patient',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-billing/:pageId',
        component: () => import('@/views/page/company/hospital/investigation/IpdBilling.vue'),
        name: 'investigation-billing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-investigation/:pageId',
        component: () => import('@/views/page/company/hospital/investigation/CreateIpdBilling.vue'),
        name: 'invoice-investigation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pos-invoice/:pageId',
        component: () => import('@/views/page/company/hospital/PosInvoice.vue'),
        name: 'pos-invoice',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-register/:pageId',
        component: () => import('@/views/page/company/hospital/ipd-register/IpdRegister.vue'),
        name: 'ipd-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-admission/:pageId',
        component: () => import('@/views/page/company/hospital/ipd-register/IpdAdmission.vue'),
        name: 'ipd-admission',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-admission/:pageId/edit/:admissionId',
        component: () => import('@/views/page/company/hospital/ipd-register/IpdAdmission.vue'),
        name: 'edit-ipd-admission',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-station/:pageId',
        component: () => import('@/views/page/company/hospital/ipd-station/IpdStation.vue'),
        name: 'ipd-station',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-statement/:pageId',
        component: () => import('@/views/page/company/hospital/ipd-station/IpdStatement.vue'),
        name: 'ipd-statement',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-statement/:pageId/edit',
        component: () => import('@/views/page/company/hospital/ipd-station/IpdStatementUpdate.vue'),
        name: 'ipd-statement-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-doctors-order/:pageId/details/:serviceId',
        component: () => import('@/views/page/company/hospital/ipd-station/DoctorOrder.vue'),
        name: 'ipd-doctors-order',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/prescription/:pageId/add/:serviceId',
        component: () => import('@/views/page/company/hospital/prescription/AddPrescription.vue'),
        name: 'add-prescription',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pharmacy-billing/:pageId',
        component: () => import('@/views/page/company/hospital/pharmacy-billing/PharmacyBilling.vue'),
        name: 'pharmacy-billing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-billing/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-billing/InvestigationBilling.vue'),
        name: 'investigation-billing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-pharmacy/:pageId/doctor-order/:orderId',
        component: () => import('@/views/page/company/hospital/pharmacy-billing/PharmacyInvoice.vue'),
        name: 'invoice-pharmacy',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-investigation/:pageId/doctor-order/:orderId',
        component: () => import('@/views/page/company/hospital/investigation-billing/InvestigationInvoice.vue'),
        name: 'invoice-investigation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-patient/:pageId',
        component: () => import('@/views/page/company/hospital/patient/Billing/PatientBilling.vue'),
        name: 'invoice-patient',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-hospital/:pageId',
        component: () => import('@/views/page/company/hospital/general-billing/InvoiceHospital.vue'),
        name: 'invoice-hospital',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pharmacy-invoice1/:pageId',
        component: () => import('@/views/page/company/hospital/pharmacy-billing-1/PharmacyBillV2.vue'),
        name: 'pharmacy-invoice1',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/notes-master/:pageId',
        component: () => import('@/views/page/company/hospital/note-master/NoteMaster.vue'),
        name: 'notes-master',
        meta: {
            requiresAuth: true
        }
    },
    //service-resources
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/service-resources/:pageId/:accountType',
        component: () => import('@/views/page/company/hospital/service-resources/List.vue'),
        name: 'service-resources',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-report-general/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/DailySalesReport.vue'),
        name: 'sales-report-general',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/user-sales-summary/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/UserSalesSummaryReport.vue'),
        name: 'user-sales-summary',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-report-unitwise/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/SalesReportUnitwise.vue'),
        name: 'sales-report-unitwise',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-report-productwise/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/SalesReportProductwise.vue'),
        name: 'sales-report-productwise',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-sales-collection-report/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/IpdSalesCollectionReport.vue'),
        name: 'ipd-sales-collection-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ipd-status-report/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/IPDPatientStatusReport.vue'),
        name: 'ipd-status-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt-statements/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/MoneyReceiptStatements.vue'),
        name: 'money-receipt-statements',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/user-collection-summary/:pageId',
        component: () => import('@/views/page/company/hospital/hospital-admin/UserCollectionSummary.vue'),
        name: 'user-collection-summary',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pharmacy-bill-list/:pageId',
        component: () => import('@/views/page/company/hospital/billing/pharmacy/InvoiceList.vue'),
        name: 'pharmacy-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/hospital-bill-list/:pageId',
        component: () => import('@/views/page/company/hospital/billing/hospital/InvoiceList.vue'),
        name: 'hospital-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/hospital-bill-add/:pageId',
        component: () => import('@/views/page/company/hospital/billing/hospital/HospitalBillAdd.vue'),
        name: 'hospital-bill-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-bill-list/:pageId',
        component: () => import('@/views/page/company/hospital/billing/investigation/InvoiceList.vue'),
        name: 'investigation-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-bill-add/:pageId',
        component: () => import('@/views/page/company/hospital/billing/investigation/InvestigationBillAdd.vue'),
        name: 'investigation-bill-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/service-resource-status',
        component: () => import('@/views/page/company/hospital/service-resources/Status.vue'),
        name: 'service-resource-status',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/InvoiceList.vue'),
        name: 'bill-manager',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId/pharmacy-bill',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/InvoiceList.vue'),
        name: 'pharmacy-bill-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/counter-bill-manager/:pageId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/CounterInvoiceList.vue'),
        name: 'counter-bill-manager',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-opd-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnOpdBill.vue'),
        name: 'opd-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/ipd-bill/:ipdRegisterId/list',
        component: () => import('@/views/page/company/hospital/billing/ipd-billing/IPDBillingLIst.vue'),
        name: 'ipd-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/ipd-bill/:ipdRegisterId/add',
        component: () => import('@/views/page/company/hospital/billing/ipd-billing/IPDBillAdd.vue'),
        name: 'ipd-bill-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/ipd-bill/:ipdRegisterId/edit/:ipdId',
        component: () => import('@/views/page/company/hospital/billing/ipd-billing/IPDBillEdit.vue'),
        name: 'ipd-bill-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId/return-pharmacy-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnPharmacyInvoice.vue'),
        name: 'pharmacy-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId/return-pharmacy-bill/:invoiceId/partial',
        component: () => import('@/views/page/company/hospital/billing/pharmacy/BillReturn.vue'),
        name: 'pharmacy-bill-partial-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-hospital-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnHospitalBill.vue'),
        name: 'hospital-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-investigation-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnInvestigationBill.vue'),
        name: 'investigation-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-investigation-bill/:invoiceId/partial/:pageId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnPartialInvestigationBill.vue'),
        name: 'investigation-partial-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-ipd-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnIpdBill.vue'),
        name: 'ipd-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId/return-general-bill/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/ReturnGeneralBill.vue'),
        name: 'general-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/:pageId/general-bill-partial-return/:invoiceId',
        component: () => import('@/views/page/company/hospital/billing/bill-manager/PartialReturnGeneralBill.vue'),
        name: 'general-bill-partial-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-heads/:pageId/:accountType',
        component: () => import('@/views/page/company/hospital/InvestigationHead/List'),
        name: 'investigation-heads',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-sample-laboratory/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-general/InvestigationBillingList.vue'),
        name: 'pending-sample-laboratory',
        meta: {
            requiresAuth: true,
            slug: 'laboratory'
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-sample-radiology-and-imaging/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-general/InvestigationBillingList.vue'),
        name: 'pending-sample-radiology-and-imaging',
        meta: {
            requiresAuth: true,
            slug: 'radiology-and-imaging'
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/investigation-invoice-report/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-invoice/List.vue'),
        name: 'investigation-invoice-report',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-sample-usg/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-general/InvestigationBillingList.vue'),
        name: 'pending-sample-usg',
        meta: {
            requiresAuth: true,
            slug: 'usg'
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-sample-:type/:pageId/reports/:billId',
        component: () => import('@/views/page/company/hospital/investigation-general/List.vue'),
        name: 'pending-sample-reports',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-sample-update/:type/:pageId/update/:saleId',
        component: () => import('@/views/page/company/hospital/investigation-general/InvestigationGeneralUpdate.vue'),
        name: 'pending-sample-update',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sample-collection-laboratory/:pageId',
        component: () => import('@/views/page/company/hospital/investigation-general/SampleCollectionLaboratory.vue'),
        name: 'sample-collection-laboratory',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/reorder-level-report/:pageId',
        component: () => import('@/views/page/company/hospital/pharmacy/ReorderLevelReport.vue'),
        name: 'reorder-level-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/expire-product-report/:pageId',
        component: () => import('@/views/page/company/hospital/pharmacy/ExpiredProductList.vue'),
        name: 'expire-product-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/group-template/:pageId',
        component: () => import('@/views/page/company/hospital/group-template/GroupTemplateList.vue'),
        name: 'group-template',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-pharmacy-template/:pageId',
        component: () => import('@/views/page/company/hospital/pharmacy-billing-template/PharmacyInvoiceTemplate.vue'),
        name: 'invoice-pharmacy-template',
        meta: {
            requiresAuth: true
        }
    }
]
