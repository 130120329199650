<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-xl" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">Production Details</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                
                <div class="modal-body">
                    
                    <div class="row gy-2 px-2">

                        <div class="card general-card">
                            <div class="table-responsive">
                                <table class="table custom-table-padding">
                                    <thead>
                                        <tr>
                                            <th>Item Details</th>
                                            <th>PI Qty</th>
                                            <th>Production Qty</th>
                                            <th>Delivery Qty</th>
                                            <th>Costing</th>
                                            <th>Production</th>
                                            <th>Delivery</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in salesGenerals" :key="i">
                                            <td>{{ item.product_name }}</td>
                                            <td>{{ item.quantity }}</td>
                                            <td>{{ item.total_pq }}</td>
                                            <td>0</td>
                                            <td>
                                                <a v-if="!item.pcm_bill_number" @click="onClickToCosting(item, 'costing')" class="fw-bold" href="javascript:void(0)">+ Costing</a>
                                                <a v-else href="javascript:void(0)">{{ item.pcm_bill_number }}</a>
                                            </td>
                                            <td>
                                                <a v-if="!item.pcm_bill_number" @click="onClickToCosting(item, 'production')" :class="!item.pcm_bill_number && 'disabled-element'" class="fw-bold" href="javascript:void(0)">+ Production</a>
                                                <a v-else href="javascript:void(0)">{{ item.pm_bill_number }}</a>
                                            </td>
                                            <td><a class="fw-bold disabled-element" href="javascript:void(0)">+ Challan</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p v-if="!salesGenerals.length" class="text-center mt-5">No data</p>
                            </div>
                        </div>

                    </div>
                
                </div>
               
            </div>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import handlePurchase from '@/services/modules/purchase'
import Loader from '@/components/atom/LoaderComponent'
import { onMounted, ref } from "vue";

const props = defineProps({
    saleMaster: {
        type: Object || null,
        default: null
    }
})

const { fetchSalesGeneralList } = handlePurchase()

const store = useStore();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const salesGenerals = ref([]);

const closeModal = () => {
    store.state.isModalOpen = false;
}

const onClickToCosting = (item, type) => {
    router.push({
        name: type === 'costing' ? 'product-costing-add' : 'production-add', 
        params: route.params,
        query: { sales_general_id: item.id }
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    return query;
}

const getSalesGeneral = async () => {
    loading.value = true

    let query = getQuery();

    await fetchSalesGeneralList(query, props.saleMaster.id).then(res => {
        if (res.status){
            salesGenerals.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

onMounted(() => {
    getSalesGeneral();
});
</script>

<style scoped>
.general-card {
    min-height: 200px;
}

.fw-bold {
    font-weight: bold !important;
}

.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>