<template>
  <!-- BEGIN: Content-->
  <div class="app-content" style="margin-top: -25px;">
    <div class="content-wrapper container-xxl p-0">

      <div class="content-header row my-1">
        <div class="content-header-left col-md-9 col-12">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <div v-if="!contactId" class="demo-inline-spacing">
                <div @click="onHandleClick('person')" class="form-check form-check-inline mt-0">
                  <input v-model="infoType" class="form-check-input" type="radio" name="inlineRadioOptions"
                         id="inlineRadio1"
                         value="person"/>
                  <label class="form-check-label" for="inlineRadio1">Person</label>
                </div>
              </div>
              <div class="form-check text-capitalize" v-else>
                <label class="form-check-label">{{ updateFormData.contact_type }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-3 col-12">
          <div class="float-end">
            <button
                type="button"
                @click="redirectBack"
                class="btn btn-close"
            >
            </button>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div id="user-profile">
          <!-- profile header -->
          <div class="row">
            <div class="col-12">
              <div class="card profile-header profile_header_visibility mb-2">
                <!-- photo and cover photo -->
                <ContactBanner
                    :name="updateFormData.full_name??''"
                    :designation="updateFormData.designation??''"
                    :company_roles_data="updateFormData.company_roles_data??''"
                    :profilePhoto="updateFormData.photo??''"
                    :coverPhoto="updateFormData.cover_photo??''"
                    :showProfilePhoto="true"
                    :isEditable="contactId"
                    @photoUploaded="uploadPhoto"
                />

                <!-- tabs pill -->
                <ContactNav
                    :activeTabId="activeTabId"
                    :tabs="tabs"
                    @setActiveTab="setActiveTab"
                    :infoType="infoType"
                />

              </div>
            </div>
          </div>
          <!--/ profile header -->

          <!-- profile info section -->
          <section id="profile-info">
            <div class="row">
              <div class="col-md-12 col-12">

                <div class="card">
                  <component :formData="updateFormData" :items="items" :companyRules="companyRules" @storeContact="storeContact"
                             :is="activeTab"/>
                </div>

              </div>
            </div>
          </section>
          <!--/ profile info section -->
        </div>

      </div>
    </div>
  </div>
  <Loader v-if="loading"/>
  <!-- END: Content-->
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Loader from '@/components/atom/LoaderComponent'
import ContactNav from '@/views/page/company/hr-payroll/employee-register/ContactNav.vue'
import BasicInfo from "@/views/page/company/hr-payroll/employee-register/tabs/person/BasicInfo.vue";
import PhoneNo from "@/views/page/company/hr-payroll/employee-register/tabs/person/PhoneNo.vue";
import Email from "@/views/page/company/hr-payroll/employee-register/tabs/person/Email.vue";
import Address from "@/views/page/company/hr-payroll/employee-register/tabs/person/Address.vue";
import Organizations from "@/views/page/company/hr-payroll/employee-register/tabs/person/Organizations.vue";
import Education from "@/views/page/company/hr-payroll/employee-register/tabs/person/Education.vue";
import LeaveApprover from "@/views/page/company/hr-payroll/employee-register/tabs/person/LeaveApprover.vue";
import SkillsExpertise from "@/views/page/company/hr-payroll/employee-register/tabs/person/SkillsExpertise.vue";
import ExtraActivity from "@/views/page/company/hr-payroll/employee-register/tabs/person/ExtraActivity.vue";
import Supervisor from "@/views/page/company/hr-payroll/employee-register/tabs/person/Supervisor.vue";
import ProfessionalReference from "@/views/page/company/hr-payroll/employee-register/tabs/person/ProfessionalReference.vue";
import ContactBanner from "@/components/molecule/company/hospital/patient/create-update/ContactBanner.vue";

import OrgBasicInfo from "@/views/page/company/contact/tabs/organization/BasicInfo.vue";
import OrgAddress from "@/views/page/company/contact/tabs/organization/Address.vue";
import ContactPerson from "@/views/page/company/contact/tabs/organization/ContactPerson.vue";
import PaymentDetails from "@/views/page/company/contact/tabs/organization/PaymentDetails.vue";
import ProductAndService from "@/views/page/company/contact/tabs/organization/ProductAndService.vue";
import BusinessLocation from "@/views/page/company/contact/tabs/organization/BusinessLocation.vue";
import handleCompanyContact from "@/services/modules/companyContact";
import handleRole from "@/services/modules/role";

import LinkedAccount from "@/views/page/company/hr-payroll/employee-register/tabs/LinkedAccount.vue";
import Fees from "@/views/page/company/hr-payroll/employee-register/tabs/Fees.vue";

const {
  storeCompanyContact,
  storeCompanyContactsItems,
  storeUpdateContactFile,
  fetchSingleCompanyContact,
  fetchSingleCompanyContactItems
} = handleCompanyContact();

const {
  fetchCompanyDefaultRoles
} = handleRole();

const route = useRoute();
const router = useRouter();
const activeTab = ref(BasicInfo)
const activeTabId = ref('basic-info')
const infoType = ref('person');
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const companyRules = ref([])
const updateFormData = ref({})
const items = ref([])

const contactId = computed(() => route.query.contactId)
function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const redirectBack = () => {
  if (contactId.value) {
    let query = {
      page: 1,
      contactId: contactId.value
    }
    router.push({name: 'employee-register', query});
    return;
  }
  router.back();
}
const onHandleClick = (value) => {
  let routeQuery = Object.assign({}, route.query);
  routeQuery.type = value
  let hashValue = value === 'person' ? '#basic-info' : '#org-basic-info';
  router.push({path: route.path, query: routeQuery, hash: hashValue});
  if (value === 'person') {
    activeTabId.value = 'basic-info';
    activeTab.value = BasicInfo;
    return;
  }
  activeTabId.value = 'org-basic-info';
  activeTab.value = OrgBasicInfo;

  if (contactId.value) {
    getSingleContact(contactId.value)
  }
}
const tabs = [
  {id: 'basic-info', module: 'person', title: 'Basic Info', component: BasicInfo},
  {id: 'org-basic-info', module: 'organization', title: 'Basic Info', component: OrgBasicInfo},
  {id: 'phone', module: 'both', title: 'Phone No', component: PhoneNo},
  {id: 'email', module: 'both', title: 'Email', component: Email},
  {id: 'contact_person', module: 'organization', title: 'Contact Person', component: ContactPerson},
  {id: 'address', module: 'person', title: 'Address', component: Address},
  {id: 'billing_address', module: 'organization', title: 'Address', component: OrgAddress},
  {id: 'organization', module: 'person', title: 'Organizations', component: Organizations},
  {id: 'payment_account_name', module: 'organization', title: 'Payment Details', component: PaymentDetails},
  {id: 'education', module: 'person', title: 'Education', component: Education},
  {id: 'product_service', module: 'organization', type: 'child', title: 'Products and services', component: ProductAndService},
  {id: 'business_location', module: 'organization', type: 'child', title: 'Business Location', component: BusinessLocation},
  {id: 'linked_account_org', module: 'organization', type: 'child', title: 'Linked Accounts', component: LinkedAccount},
  {id: 'contact_person_fees', module: 'organization', type: 'child', title: 'Fees', component: Fees},
  {id: 'skills_expertise', module: 'person', type: 'child', title: 'Skills / Expertise', component: SkillsExpertise},
  {id: 'extra_activity', module: 'person', type: 'child', title: 'Extra Activity', component: ExtraActivity},
  {id: 'linked_account_person', module: 'person', type: 'child', title: 'Linked Accounts', component: LinkedAccount},
  {id: 'contact_person_fees', module: 'person', type: 'child', title: 'Fees', component: Fees},
  {
    id: 'professional_reference',
    module: 'person',
    type: 'child',
    title: 'Professional Reference',
    component: ProfessionalReference
  },
  {id: 'leave_approver', module: 'person', title: 'Leave Approver', component: LeaveApprover},
  {id: 'supervisor', module: 'person', type: 'child', title: 'Supervisors', component: Supervisor},
]

const uploadPhoto = async (image, type) => {
  loading.value = true
  let form = new FormData();
  form.append('file', image)
  form.append('type', type)

  storeUpdateContactFile(contactId.value, form, getQuery()).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    updateFormData.value[type] = res.data;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const storeContact = (formData) => {
  let payload = {
    contact_type: infoType.value,
    contact_id: contactId.value,
    active_tab: activeTabId.value,
    ...formData
  }

  const validTabs = ['basic-info', 'org-basic-info', 'address'];
  const checkActiveTab = validTabs.includes(activeTabId.value);

  const routeRes = checkActiveTab ? storeCompanyContact(payload, getQuery())
      : storeCompanyContactsItems(payload, getQuery())
  routeRes.then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }

    if (!checkActiveTab) {
      items.value = res.data
    }else {
      let routeQuery = Object.assign({}, route.query)
      routeQuery.contactId = res.data.id
      let hashValue = infoType.value === 'person' ? '#basic-info' : '#org-basic-info';
      router.push({path: route.path, query: routeQuery, hash: hashValue})
      updateFormData.value = res.data
    }
    showSuccess(res.message)
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}
const getSingleContact = (id) => {
  loading.value = true
  let query = getQuery();
  query += "&active_tab=" + activeTabId.value;
  fetchSingleCompanyContact(query, id).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    updateFormData.value = res.data
    items.value = res.data.items
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const getSingleContactItems = () => {
  loading.value = true
  let query = getQuery();
  query += "&active_tab=" + activeTabId.value;
  fetchSingleCompanyContactItems(query, contactId.value).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    items.value = res.data
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  loading.value = true;
  let companyQuery = `?company_id=${route.params.companyId}`
  companyQuery += "&roles=employee";
  const rolesRes = fetchCompanyDefaultRoles(companyQuery)
  Promise.all([
    rolesRes.then(res => {
      if (res.data) companyRules.value = res.data
    })
  ])
      .then(() => {
        loading.value = false
      })
      .catch((err) => {
        loading.value = false
      })

  const hashPath = route.hash.substring(1);
  if (hashPath) {
    activeTabId.value = hashPath;
    const tab = tabs.find(item => (item.id === hashPath || item.id === 'more'));
    activeTab.value = tab.component;
  }

  if (contactId.value) {
    getSingleContact(contactId.value)
  }

  if (route.query.type ?? '') {
    infoType.value = route.query.type;
  }
})

const setActiveTab = tab => {
  activeTab.value = tab.component;
  activeTabId.value = tab.id;

  const validTabs = ['basic-info', 'org-basic-info', 'address'];
  const checkActiveTab = validTabs.includes(activeTabId.value);
  if (!checkActiveTab){
    getSingleContactItems()
  }
};


</script>


<style>

.org_icon_col {
  padding-bottom: 5%;
  position: relative;
}

.org_dlt_icon {
  position: absolute;
  right: 0px;
  top: -17px;
}

.button_icon {
  border: none !important;
  padding: 4px 15px !important;
}

.p_left_0 {
  padding-left: 0 !important;
}

.header_txt {
  /* margin-left: -15% !important; */
  justify-content: space-evenly !important;
}

.box_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card_body {
  padding: 2% 2%;
  width: 48%;
  margin: 1%;
  border-radius: 5px
}

@media (max-width: 414px) {
  .card_body {
    width: auto !important;
  }

  ._skill_btn_visibility {
    display: grid !important;
  }

  ._skill_out_visibility {
    display: none !important;
  }

  .org_icon_col {
    padding-bottom: 10% !important;
  }

  .org_dlt_icon {
    top: -8px !important;
  }
}
</style>
<style scoped>

.profile_sub_menu.dropdown-menu {
  min-width: 11rem !important;
}

.profile_sub_menu .nav-pills .nav-link, .nav-tabs .nav-link {
  justify-content: center !important;
}


</style>
