<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Attendance Report"
            />
        </div>
        <div class="px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div  class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label for="colFormLabel" class="col-form-label">Employee</label>
                <v-select
                    placeholder="Select Employee"
                    v-model="contactProfileId"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2 mb-1">
          <span class="red mx-1">A: Absent</span>
          <span class="orange mx-1">LT: Late</span>
          <span class="darkgreen mx-1">H: Holiday</span>
          <span class="darkblue mx-1">W: Weekend</span>
          <span class="purple mx-1">L:Late</span>
        </div>

        <div class="col-12 px-2 mb-4">
          <div class="table-responsive summary-table">
            <table class="table" border="1" width="100%">
              <thead>
              <tr>
                <th>Employee</th>
                <th>In/Out</th>
                <th class="nowrap" v-for="day in daysInMonth" :key="day">{{ day }}</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="employee in tableItems" :key="employee.contact_profile_id">
                <tr>
                  <td rowspan="2">
                    <div>{{ employee.name }}</div>
                    <div>{{ employee.designation }}</div>
                  </td>
                  <td>IN</td>
                  <td class="nowrap" :class="getColor(employee.attendances,day)" v-for="day in daysInMonth" :key="day">
                    {{ getTime(employee.attendances, day, 'in_time') }}
                  </td>

                  <td rowspan="2" class="nowrap">
                    <div>
                      <div>{{ formatTotal(employee.total) }}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>OUT</td>
                  <td class="nowrap" :class="getColor(employee.attendances,day)" v-for="day in daysInMonth" :key="day">
                    {{ getTime(employee.attendances, day, 'out_time') }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>

      <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import { computed } from 'vue';
import handleContact from '@/services/modules/contact'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const contactProfileId = ref('');
const params = ref({
    offset: 30,
});
let contacts = ref([])

const token = computed(() => localStorage.getItem('token'));

const {
  fetchAttendanceSummaryReport,
} = handleHrPayroll()
const { fetchContactProfiles } = handleContact()

const onClickToGo = () => {
  getAttendanceSummary();
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getAttendanceSummary();
}
const getAttendanceSummary = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchAttendanceSummaryReport(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (contactProfileId.value) query += '&contact_profile_id=' + contactProfileId.value
    query += '&offset=' + params.value.offset
    return query;
}

onMounted(() => {
  const contactQuery =  `?company_id=${route.params.companyId}&type=employee`
  const contactRes   = fetchContactProfiles(contactQuery)

    Promise.all([
      getAttendanceSummary(),
      contactRes.then(res => {
        if (res.data) contacts.value = res.data
      }),
    ])
})


const daysInMonth = computed(() => {
  if (!route.query.start || !route.query.end) {
    return [];
  }

  const startDate = new Date(route.query.start);
  const endDate = new Date(route.query.end);

  if (isNaN(startDate) || isNaN(endDate) || startDate > endDate) {
    return [];
  }

  const days = [];
  let currentDate = new Date(startDate);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  while (currentDate <= endDate) {
    const day = currentDate.getDate();
    const month = monthNames[currentDate.getMonth()];
    days.push(`${day}-${month}`);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return days;
});


const getTime = (data, day, type) => {
  const [dayPart, monthPart] = day.split('-');
  const record = data.find((d) => d.calendar_date.endsWith(`-${dayPart.toString().padStart(2, '0')}`));
  return record[type] || record.status ;
};

const getColor = (data, day) =>  {
  const [dayPart, monthPart] = day.split('-');
  const record = data.find((d) => d.calendar_date.endsWith(`-${dayPart.toString().padStart(2, '0')}`));
  return {
    'red': record.status === 'A',
    'orange': record.status === 'LT',
    'darkgreen': record.status === 'H',
    'darkblue': record.status === 'W',
    'purple': record.status === 'L',
  };
}

const formatTotal = (total) => {
  return Object.entries(total)
      .map(([status, count]) => `${status}: ${count}`)
      .join(', ');
};

</script>

<style>
  .summary-table .nowrap{
    white-space: nowrap !important;
  }

  .red{
    --bs-bg-opacity: 1;
    color:red;
   }
  .purple{
    --bs-bg-opacity: 1;
    color:purple;
  }
  .darkgreen{
    --bs-bg-opacity: 1;
    color:darkgreen;
  }
  .darkblue{
    --bs-bg-opacity: 1;
    color:darkblue;
  }
  .orange{
    --bs-bg-opacity: 1;
    color:orange;
  }
</style>