<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Sales Order Edit"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="col-md-3">
                    <label class="form-label">Order Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-3" v-if="false">
                    <label class="form-label">Order No</label>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                                <span>{{ prefix }}-</span>
                            </div>
                            <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                                   v-model="serial">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-1">
                <div class="col-md-3">
                    <label class="form-label">Party</label>
                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactQuery"
                        :format-label="formatPatientLabel"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Project</label>
                    <v-select
                        placeholder="Select Project"
                        v-model="formData.project_id"
                        :options="projects"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Business Unit</label>
                    <v-select
                        placeholder="Select Business"
                        v-model="formData.business_id"
                        :options="business"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Cost Centre</label>
                    <v-select
                        placeholder="Select Cost Centre"
                        v-model="formData.cost_centre_id"
                        :options="costCentres"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No/PI No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add order number"
                        v-model="formData.order_number"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Sales Person</label>
                    <v-select
                        placeholder="Select Sales Person"
                        v-model="formData.sales_person_id"
                        :options="contacts"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Commission %</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="number"
                        placeholder="Please add commission %"
                        v-model="formData.commission_percent"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">LC No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add LC number"
                        v-model="formData.lc_number"
                    >
                </div>
                <div class="col-md-3" v-if="route.query.type === 'approval'">
                    <label class="form-label">Order Type</label>
                    <v-select
                        placeholder="Select Order Type"
                        v-model="formData.sale_type"
                        :options="orderTypes"
                        label="name"
                        :reduce="name => name.value"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Ship from (Warehouse)</label>
                    <v-select
                        placeholder="Select Warehouse Location"
                        v-model="formData.location_id"
                        :options="locations"
                        label="text"
                        :reduce="text => text.id"
                    />
                </div>
            </div>
        </div>

        <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
                </li>
                <li class="nav-item">
                    <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
                </li>
            </ul>
        </div>

        <div class="px-3" v-if="loader == false">
            <Invoice
                class="mt-2"
                v-for="(data, index) in accountsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
            />
            <Invoice
                class="mt-2"
                v-for="(data, index) in itemsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
            />
        </div>

        <div class="px-2 mt-2" v-if="formData.status != 'approved'">
            <button @click="addNewBill" class="btn btn-primary">Add line</button>
        </div>

        <div class="px-2">
            <div class="row justify-content-end">
                <div class="col-12 col-sm-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Sub total</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="subTotal"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">VAT</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="totalVAT"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Total</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="total"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Discount / Round up Adj</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model.trim="formData.adjustment_amount"
                                        type="number"
                                        class="form-control discount-adj ml-auto text-right"
                                        placeholder="amount" :min="0"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="discountAmount > 0">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Select discount head</label>
                                </div>
                                <div class="col-sm-8">
                                    <v-select
                                        placeholder="Select Discount Head"
                                        v-model="formData.discount_head_id"
                                        :options="salesAccountHeads"
                                        label="name"
                                        :reduce="name => name.id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Net Bill</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model.trim="grandTotal"
                                        type="number" class="form-control discount-adj ml-auto text-right"
                                        placeholder="amount" :min="0"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Memo</label>
                <vField
                  as="textarea"
                  name="description"
                  v-model="formData.description"
                  class="form-control"
                />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4" v-if="formData.status != 'approved'">
            <div class="d-flex flex-wrap gap-1 gy-2">
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
                  Update
                </button>
                <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
                <button v-if="route.query.type === 'approval'" :disabled="productLoader" @click="approveOrder" class="btn btn-success">Approve</button>
            </div>
        </div>
         <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import { ref, inject, reactive, onMounted, computed, watch } from 'vue'
import {vatRate} from '@/data/inventory.js'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import Loader from '@/components/atom/LoaderComponent'
import TitleButton from '@/components/atom/TitleButton'
import Invoice from '@/components/molecule/company/inventory/sales/Invoice.vue'
import { editVoucherNumber } from "../../../../../../services/utils/voucherNumberGenerator";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const router = useRouter()
const route = useRoute()
let prefix = ref('')
let serial = ref('')

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const { formatPatientLabel } = useAsyncDropdownHelper();
const {fetchContactProfiles} = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres()

const companyId = computed(() => {
    return route.params.companyId
})
let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let vatPayable = ref([])
let fetchedFromAPI = ref(false)
let projects = ref([])
let costCentres = ref([])
const orderTypes = ref([])
const prevStatus = ref('');
const contactProfile = ref(null)
let salesAccountHeads = ref([])
const grandTotal = ref(null);
const additionalContactQuery = ref({
    with_mobile: 1,
    with_donors: 1,
    roles: 'customer,lead'
});

onMounted(async () => {
    loader.value=true
    const companyQuery = `?company_id=${companyId.value}`
    let projectQuery = companyQuery + '&with_donors=1';
    const productRes = fetchProductList(companyQuery)
    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const payableRes = rest.fetchAccountReceivable(companyQuery)
    const vatPayableRes = rest.fetchVatPayable(companyQuery)
    const fetchSingleSalesOrder = rest.fetchSalesOrderById(route.params.salesId, companyQuery)
    const projectRes = fetchProjects(projectQuery)
    const costCenterRes = fetchCostCentreList(companyQuery)
    const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)

    Promise.all([
        rest.fetchSalesOrderTypes(companyQuery).then(res => {
            if(res.data) orderTypes.value = res.data
        }),
        productRes.then(res=> {
            if(res.data) products.value = res.data
        }),
        accountHeadRes.then(res=> {
            if(res.data) accountHeads.value = res.data
        }),
        businessRes.then(res=> {
            if(res.data) business.value = res.data
        }),
        businessLocationRes.then(res=> {
          if(res.data) locations.value = res.data
        }),
        projectRes.then(res=> {
          if(res.data) projects.value = res.data
        }),
        costCenterRes.then(res=> {
          if(res.data) costCentres.value = res.data
        }),
        payableRes.then(res=> {
            if(res.data) accountPayable.value = res.data
            if(res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        vatPayableRes.then(res=> {
          if(res.data) vatPayable.value = res.data
          if(res.data?.length) {
            formData.value.vat_payable_account_head_id = res.data[0].id
          }
        }),
        fetchSingleSalesOrder.then(res => {
            if(res.data) {
                let voucher = editVoucherNumber(res.data.bill_number);
                prefix.value = voucher.prefix;
                serial.value = voucher.serial;
                fetchedFromAPI.value = true
                formData.value = res.data
                if(res.data.has_item_detail) {
                  formData.value.item_details = res.data.general
                  formData.value.has_item_detail = true
                } else {
                  formData.value.account_details = res.data.ledgers
                  formData.value.has_item_detail = false
                }

                if(res.data.contact){
                    contactProfile.value = res.data.contact
                }
            }
        }),
        salesAccountHeadsRes.then(res => {
            if(res.data) salesAccountHeads.value = res.data
        })
    ])
    .then(() => {loader.value=false})
    .catch((err)=>{loader.value=false})
//   getProduct()
})
let formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    vat_payable_account_head_id: null,
    business_id: null,
    project_id: null,
    cost_centre_id: null,
    location_id: null,
    status: 'active',
    bill_number: '',
    date: '',
    has_item_detail: true,
    description: '',
    account_details: [],
    sale_type: null,
    discount_head_id: null,
    adjustment_amount: null,
    item_details: [
        {
            product_id: null,
            quantity: 1,
            rate: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            description: "",
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }
    ],
})

const hasItemDetails = computed(() => {
    return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
    return formData.value.item_details
})
const accountsDetails = computed(() => {
    return formData.value.account_details
})

const discountAmount = computed(() => formData.value.adjustment_amount);

const isItem = computed(() => {
    return formData.value.has_item_detail ? true : false
})
const selectionText = computed(() => {
    return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

const subTotal = computed(() => {
    let subTotal=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseFloat(((item.quantity * item.rate) - item.discount_amount - item.offer_discount).toFixed(4))
        })
    }
    if(!formData.value.has_item_detail && formData.value.account_details) {
        formData.value.account_details.map(item => {
            if(item.amount) {subTotal += parseInt(item.amount)}
        })
    }

    return subTotal
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }
    if(!formData.value.has_item_detail && formData.value.account_details) {
        formData.value.account_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }

    return vat.toFixed(2)
})

const total = computed(() => {
    return (parseFloat(subTotal.value) + parseFloat(totalVAT.value)).toFixed(2);
})

watch(hasItemDetails, (newValue) => {
    if(!newValue) {
      if(fetchedFromAPI.value === false){
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        }]
      } else {
          fetchedFromAPI.value = false
      }
    }
    if(newValue) {
      if(fetchedFromAPI.value === false) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            discount_amount: 0,
            discount_percent: 0,
            description: ""
        }]
      } else {
          fetchedFromAPI.value = false
      }
    }
})

watch(discountAmount, (newValue, oldValue) => {
    if (! newValue || newValue < 0) {
        grandTotal.value = total.value;
        formData.value.adjustment_amount = null;
        return;
    }

    if (newValue !== oldValue) {
        grandTotal.value = total.value - discountAmount.value
    }
})

watch(total, () => {
    grandTotal.value = total.value - discountAmount.value;
})

watch(grandTotal, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        formData.value.adjustment_amount = parseFloat((total.value - newValue).toFixed(4));
    }
})

function navigateToListPage() {
    const query = Object.assign({}, route.query)
    delete query.type
    delete query.visit_client_id;

    router.push({
      name:route.query.type === 'approval'
          ? 'sales-order-approval'
          : (route.query.type === 'order-sales'
              ? 'order-sales'
              : 'sales-order-list'),
      params: route.params,
      query: query
    })
}

const approveOrder = () => {
    prevStatus.value = formData.value.status
    formData.value.status = 'approved';
    handleSubmit(true);
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id: null,
            quantity: 1,
            rate: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            business_id: null,
            project_id: null,
            description: ''
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        })
    }
}
function handleSubmit(redirect=false) {
    if (! formData.value.vat_payable_account_head_id){
      showError('Please select a vat payable account head');
      return;
    }
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    formData.value.bill_number = prefix.value+'-'+serial.value
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    if(isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    }else {
         delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }

    copyFormData.contact_profile_id = contactProfile.value ? contactProfile.value.id : null

    rest.updateSalesOrder(route.params.salesId,  copyFormData)
    .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
        if(res.status) {
            showSuccess(res.message)
            if(redirect) navigateToListPage()
            resetForm()
        }
        if(!res.status) {
            showError(res.message)
        }

        if(!res.status && formData.value.status === 'approved') {
            formData.value.status = prevStatus.value
        }
    })
    .catch( () => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
    })
}

// have to refactor
function resetForm() {
    if(!isItem.value) {
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            discount_amount: 0,
            discount_percent: 0,
            taxable_amount: null,
            business_id: null,
            project_id: null,
            tax_rate: null
        }]
    }
    if(isItem.value) {
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            description: ""
        }]
    }
}

</script>
