<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Leave Balance Summary"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div  class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label for="colFormLabel" class="col-form-label">Employee</label>
                <v-select
                    placeholder="Select Employee"
                    v-model="contactProfileId"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/hrm-payroll/leave-balance-summary/ListTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import { computed } from 'vue';
import handleContact from '@/services/modules/contact'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const contactProfileId = ref('');
const params = ref({
    offset: 30,
});
let contacts = ref([])

const token = computed(() => localStorage.getItem('token'));

const {
  fetchLeaveBalanceSummary,
} = handleHrPayroll()
const { fetchContactProfiles } = handleContact()

const onClickToGo = () => {
  getLeaveList();
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getLeaveList();
}
const getLeaveList = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchLeaveBalanceSummary(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (contactProfileId.value) query += '&contact_profile_id=' + contactProfileId.value
    query += '&offset=' + params.value.offset
    return query;
}

onMounted(() => {
  const contactQuery =  `?company_id=${route.params.companyId}&type=employee`
  const contactRes   = fetchContactProfiles(contactQuery)

    Promise.all([
      getLeaveList(),
      contactRes.then(res => {
        if (res.data) contacts.value = res.data
      }),
    ])
})

</script>