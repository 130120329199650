<template>
  <div>
    <Banner
      :photo="company.logo"
      :coverPhoto="company.cover_photo"
      :name="company.name"
      :industry="company.industry"
      :address="company.address"
      :showProfilePhoto="true"
      :allowUpdate="true"
      @updateProfile="openCompanyUpdateModal"
      @photoUploaded="uploadPhoto"
    />
    <CompanyUpdateModal
      :loading="companyUpdateLoader"
      :companyData="companyData"
      @onClickSubmit="updateCompany"
      ref="companyEdit"
    />

    <ModuleCard
      :modules="modules"
    />

    <!-- user section -->
     <div class="card shadow">
        <div class="card-body  p-sm-0">
            <div class="d-flex justify-content-between align-items-baseline p-1">
                <h4 class="card-title mb-0 ml-0">User List</h4>
                <div class="d-flex align-items-baseline">
                  <router-link
                      class="card-title"
                      :to="{
                        name: 'user-role',
                        params: {
                          companyId:$route.params.companyId,
                          moduleId:$route.params.moduleId,
                        }
                      }"
                  >User role</router-link>
                  <button @click="openUserAddModal" class="btn btn-text">Add new User</button>
                </div>
            </div>
            <hr>

            <div class="px-2 mb-5 pt-1">
              <UserListTable :users="users" v-slot="{user}" :user-wise-modules="userWiseModules">
                <router-link 
                    :to="{ name: 'user-role-permission', params: { companyId:$route.params.companyId, moduleId:$route.params.moduleId, userId:user.id, roleId:user.company_user_roles?.[0].id }}"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
                  <img width="16" src="/app-assets/images/icons/plus-solid.svg" alt="">
                </router-link>
                  <button
                    @click="openUserRoleEditModal(user)"
                    type="button"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
                    <img width="16" src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
                  </button>
                  <button
                    @click="resendMail(user)"
                    type="button"
                    :disabled="isResendingMail"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect text-dark"
                    style="font-size: 1.25rem"
                    v-if="user.status === 'pending'"
                  >
                    <i class="fas fa-mail-bulk"></i>
                  </button>
              </UserListTable>
            </div>
        </div>
    </div>
    <!-- user section -->
    <UserInviteModal
      @onSendRequest="handleInvitation"
      @onUpdateRoleRequest="updateRoleRequest"
      :editMode="editMode"
      :eventName="eventName"
      :formData="inviteData"
      :roles="roles"
      ref="user"
    />
    <GlobalLoader />
  </div>
</template>

<script>
import {mapMutations, useStore} from 'vuex'
import { computed } from 'vue'
import handleCompany from '@/services/modules/company'
import handleUser from '@/services/modules/user'
import { inject } from 'vue'
import Banner from '@/components/atom/Banner'
import CompanyUpdateModal from '@/components/molecule/company/company-setting/edit/CompanyEditModal'
import ModuleCard from '@/components/molecule/company/company-setting/ModuleCard'

import UserListTable   from '@/components/molecule/company/user/UserListTable'
import UserInviteModal from '@/components/molecule/company/user/UserInviteModal'

export default {
  name: 'CompanySetting',

  components:{
    Banner,
    CompanyUpdateModal,
    ModuleCard,
    UserInviteModal,
    UserListTable
  },

  data:()=>({
    companyUpdateLoader: false,
    currentEditUser: '',
    editMode: false,
    eventName: '',
    companyData: {},
    users: [],
    userWiseModules: [],
    roles:[],
    isResendingMail: false,
    inviteData: {
      name: '',
      company_role_ids: [],
      company_id: '',
      email: '',
      status: 'pending'
    }
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    }
  },

  methods:{
    ...mapMutations({
      setSidebar: 'company/setSidebar',
      updateCompanyInfo: 'company/updateCompanyInfo'
    }),

    openUserAddModal() {
      this.eventName='onSendRequest'
      this.editMode=false
      this.resetRoleAddForm()
      this.$refs.user.toggleModal()
    },

    openUserRoleEditModal(user) {
      this.eventName='onUpdateRoleRequest'
      this.editMode=true
      let inviteData = this.inviteData
      const {user_id, company_user_roles, status, user_name} = user
      this.currentEditUser = user_id
      inviteData.status = status
      inviteData.name = user_name
      inviteData.email = 'email@email.com'
      inviteData.company_role_ids = company_user_roles.map(company_user_role => company_user_role.id);
      this.$refs.user.toggleModal()
    },

    resetRoleAddForm() {
      let inviteData = this.inviteData
      inviteData.name= '',
      inviteData.company_role_ids= null,
      inviteData.company_id= this.companyId,
      inviteData.email= '',
      inviteData.status= 'pending'
    },

    getUserlist() {
      this.userList(this.companyId)
      .then(res => {
        if(res.status) {
            this.users = res.data
            this.userWiseModules = res.user_modules
        }
      })
    },

    // update company
    async updateCompany(){
      let fd = new FormData();
      Object.keys(this.companyData).forEach(item => {
        fd.append(item, this.companyData[item])
      })
      try {
        this.companyUpdateLoader = true
        let res = await this.fetchUpdateCompany(this.companyId,fd)
        if(!res.status) {
          this.showError(res.message)
        }
        if(res.status) {
          this.showSuccess(res.message)
          this.updateCompanyInfo(res.data)
          this.$refs.companyEdit.toggleModal()
        }
      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.companyUpdateLoader = false
      }
    },

    openCompanyUpdateModal(){
      this.companyData = Object.assign({}, this.company)
      this.companyData._method='PATCH'
      delete this.companyData.menu_schema
      delete this.companyData.user_id
      delete this.companyData.area_id
      delete this.companyData.cover_photo
      delete this.companyData.logo
      delete this.companyData.logo64
      delete this.companyData.chart_of_accounting
      delete this.companyData.company_start_date
      this.$refs.companyEdit.toggleModal()
    },

    handleInvitation() {
      this.$refs.user.loading = true
      this.sendUserRequest(this.inviteData)
      .then(res => {
        if(!res.status) this.showError(res.message)
        if(res.status) {
          this.showSuccess(res.message)
          this.$refs.user.toggleModal()
          this.getUserlist()
        }
        this.$refs.user.loading = false
      })
      .catch(err => {
        this.$refs.user.loading = false
      })
    },

    updateRoleRequest() {
      const { company_role_ids, status, name} = this.inviteData
      this.updateUserRoleRequest({
        company_id: this.companyId,
        company_role_ids: company_role_ids,
        _method: 'PATCH',
        status: status,
        name: name
      }, this.currentEditUser)
      .then(res => {
        if(!res.status) this.showError(res.message)
        if(res.status) {
          this.showSuccess(res.message)
          this.$refs.user.toggleModal()
          this.resetRoleAddForm()
          this.getUserlist()
        }
      })
      .catch(err => {
      })
    },

    async uploadPhoto(image, type){
      let fd = new FormData();
      fd.append('_method', 'patch')
      fd.append('name', this.company.name)
      fd.append('status', this.company.status)
      if (type == "cover_photo"){
        fd.append('cover_photo', image)
      }else{
        fd.append('logo', image)
      }
      try {
        this.companyUpdateLoader = true
        let res = await this.fetchUpdateCompany(this.companyId,fd)
        if(!res.status) {
          this.showError(res.message)
        }
        if(res.status) {
          this.showSuccess(res.message)
          this.updateCompanyInfo(res.data)
        }
      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.companyUpdateLoader = false
      }
    },

    resendMail(user) {
      this.isResendingMail = true;
      this.resendUserMail({company_id: this.companyId}, user.id)
        .then(res => {
          if(res && res.status === true) {
            this.showSuccess(res.message)
            return;
          }

          this.showError(res.message)
        })
        .catch(err => {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }

          this.showError(err.response.message)
        })
        .finally(() => this.isResendingMail = false)
    }
  },

  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const store = useStore()

    const {
      fetchUpdateCompany,
    } = handleCompany()

    const {
      fetchRoles,
      sendUserRequest,
      userList,
      updateUserRoleRequest,
      resendUserMail
    } = handleUser()

    return {
      company: computed(()=> store.state.company.company),
      modules: computed(()=> store.state.company.modules),
      fetchUpdateCompany,
      showError,
      showSuccess,
      fetchRoles,
      sendUserRequest,
      userList,
      resendUserMail,
      updateUserRoleRequest
    }
  },

  mounted() {
    this.inviteData.company_id = this.companyId

    this.fetchRoles(this.companyId)
    .then(res => {
      if(res.data) {
        this.roles = res.data
      }
    })

    this.getUserlist()
  }
}
</script>

<style scoped>
  .cancel-btn{
    background-color: #F0F2F5; color: #7D7D7D;
  }
  .cancel-btn:hover{
    background-color: #e9edf5;
  }
  hr{
    margin: 0 -3%;
    width: 106%;
  }
@media screen and (min-width: 1200px) {
  .btn-close {
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
    border-radius: 50%;
  }
}
@media screen and (max-width: 720px) {
  hr{
  margin: 0 -8%;
  width: 116%;
}
.btn-close {
    width: 25px;
    height: 25px;
    background-color: #E4E6EB;
    border-radius: 50%;
    margin-top: -5px;
    margin-right: -5px;
  }
}
</style>
